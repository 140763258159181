<template>
  <div class="vx-row">
    <div class="vx-col w-full">
      <vs-popup title="ENVOYER UN TEST" :active.sync="messageTestPopup">
        <span>
          Pour envoyer un message de test, vous devez fournir des coordonnées valides.
        </span>
        <div class="vx-row">
          <div class="vx-col w-full">
            <vs-input class="w-full mt-4" type="text" label="Destination"  name="Destination" v-model="singleDestination" />
          </div>
        </div>
        <div class="vx-row mt-4">
          <div class="vx-col sm:w-2/3 w-full ml-auto">
            <vs-button class="mr-3 mb-2" @click="SendTestSmsMessage">Envoyer</vs-button>
            <vs-button color="warning" type="border" class="mb-2" @click="singleDestination = ''; messageTestPopup = false;">Annuler</vs-button>
          </div>
        </div>
      </vs-popup>
      <vx-card id="Campagne">
        <vs-sidebar click-not-close position-right parent="body" default-index="1" color="primary" class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocal">
          <div class="mt-6 flex items-center justify-between px-6">
            <h4>Importer des contact</h4>
            <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
          </div>
          <vs-divider class="mb-0"></vs-divider>
          <div class="vx-row p-6">
            <div class="vx-col w-full mb-2">
              <import-excel :onSuccess="loadDataInTable" />
            </div>
          </div>
          <component :is="scrollbarTag" class="scroll-area--data-list-add-new" :key="$vs.rtl">
            <div class="vx-row p-6">
              <div class="vx-col w-full mb-8" v-if="tableData.length && header.length">
                <vs-table stripe pagination :max-items="100" :data="tableData">
                  <template slot="header">
                    <h4>Nombre de contacts: {{ tableData.length }}</h4>
                  </template>

                  <template slot="thead">
                    <vs-th :sort-key="heading" v-for="heading in header" :key="heading">{{ heading }}</vs-th>
                  </template>

                  <template slot-scope="{data}">
                    <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                      <vs-td :data="col" v-for="(col, indexcol) in data[indextr]" :key="indexcol + col">
                        {{ col }}
                      </vs-td>
                    </vs-tr>
                  </template>
                </vs-table>
              </div>
            </div>
          </component>

          <div class="flex flex-wrap items-center p-6 " slot="footer">
            <vs-button class="mr-6" @click="ImportContact">Valider</vs-button>
            <vs-button type="border" color="danger" @click="isSidebarActiveLocal = false">Annuler</vs-button>
          </div>
        </vs-sidebar>
        <form-wizard color="rgba(var(--vs-primary), 1)" errorColor="rgba(var(--vs-danger), 1)" :title="null" :subtitle="null" backButtonText="Précédent" nextButtonText="Suivant" finishButtonText="Enregistrer" @on-complete="createDiffusion()">
          <tab-content title="Générale">
            <div class="vx-row">
              <div class="vx-col md:w-1/2">
                <vs-input class="w-full mt-4" type="text" label="Titre"  name="Diffusion" :value="titreCampagne" @change="titreCampagne=$event.target.value" />
              </div>
              <div class="vx-col md:w-1/2">
                <vs-input class="w-full mt-4" type="text" label="Origine"  name="from" :value="from" @change="from=$event.target.value" />
              </div>
              <div class="vx-col md:w-3/4">
                <!-- {{contactClientsOfficines}} -->
                <vs-select label="Répertoires" autocomplete multiple class="selectExample w-full mt-4" v-model="contactClientsOfficines" @change="addDestination()">
                  <vs-select-item :key="index" :value="item.contacts" :text="item.libelle" v-for="(item,index) in repertoires" />
                </vs-select>
              </div>
              <div class="vx-col md:w-1/4 mt-5">
                <vs-button class="mt-4" @click="isSidebarActiveLocal=true">Importer Contacts</vs-button>
              </div>
              <div class="vx-col w-full text-right mt-4">
                {{Math.ceil((text.length/160))}} message / {{countMessage()}} caractères restant
              </div>
              <div class="vx-col w-full">
                <vs-textarea class="w-full" :value="text" @change.native="text=$event.target.value;countMessage()" label="message" height="200px" />
              </div>
              <div class="vx-col md:w-1/3">

              </div>
              <div class="vx-col md:w-1/3">

              </div>
              <div class="vx-col md:w-1/3">
                <vs-select label="Jeu de caractères" class="selectExample w-full mt-4" v-model.lazy="languageCode">
                  <vs-select-item :key="index" :value="item" :text="item.text" v-for="(item,index) in options2" />
                </vs-select>
              </div>
            </div>
          </tab-content>
          <tab-content title="Оptions de planification">
            <div class="vx-row">
              <div class="vx-col w-full">
                <vx-card :noShadow="true" :cardBorder="true" title="Début de la diffusion">
                  <div class="vx-row">
                    <div class="vx-col md:w-1/4">
                      <label for="sendAt">Date de début</label>
                      <flat-pickr v-model.lazy="sendAt" :config="{ dateFormat: 'd F Y'}" class="w-full" name="sendAt" />
                    </div>
                    <div class="vx-col md:w-1/4">
                      <vs-select label="Heure de debut" autocomplete name="hourAt" class="selectExample w-full" v-model.lazy="hourAt">
                        <vs-select-item :key="index" :value="item" :text="item" v-for="(item,index) in [0,1,2,3,5,6,7,8,9,10,12,13,14,15,16,17,18,19,20,21,22,23]" />
                      </vs-select>
                    </div>
                    <div class="vx-col md:w-1/4">
                      <vs-select label="Minute" autocomplete name="minuteAt" class="selectExample w-full" v-model.lazy="minuteAt">
                        <vs-select-item :key="index" :value="item" :text="item" v-for="(item,index) in [0,1,2,3,5,6,7,8,9,10,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59]" />
                      </vs-select>
                    </div>
                    <div class="vx-col md:w-1/4">
                      <vs-select label="Fuseau horaire" autocomplete class="selectExample w-full" v-model.lazy="fuseauHoraire">
                        <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item,index) in options3" />
                      </vs-select>
                    </div>
                  </div>
                </vx-card>
              </div>
              <div class="vx-col w-full mt-5">
                <vx-card :noShadow="true" :cardBorder="true" title="Créneau horaire de l’envoi">
                  <div class="vx-row">
                    <div class="vx-col md:w-1/5">
                      <vs-select label="Heure de debut" autocomplete name="hour" class="selectExample w-full" v-model.lazy="hour">
                        <vs-select-item :key="index" :value="item" :text="item" v-for="(item,index) in [0,1,2,3,5,6,7,8,9,10,12,13,14,15,16,17,18,19,20,21,22,23]" />
                      </vs-select>
                    </div>
                    <div class="vx-col md:w-1/5">
                      <vs-select label="Minute debut" autocomplete name="minute" class="selectExample w-full" v-model.lazy="minute">
                        <vs-select-item :key="index" :value="item" :text="item" v-for="(item,index) in [0,1,2,3,5,6,7,8,9,10,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59]" />
                      </vs-select>
                    </div>
                    <div class="vx-col md:w-1/5">
                      <vs-select label="Heure de Fin" autocomplete name="hour" class="selectExample w-full" v-model.lazy="toHour">
                        <vs-select-item :key="index" :value="item" :text="item" v-for="(item,index) in [0,1,2,3,5,6,7,8,9,10,12,13,14,15,16,17,18,19,20,21,22,23]" />
                      </vs-select>
                    </div>
                    <div class="vx-col md:w-1/5">
                      <vs-select label="Minute fin" autocomplete name="minute" class="selectExample w-full" v-model.lazy="toMinute">
                        <vs-select-item :key="index" :value="item" :text="item" v-for="(item,index) in [0,1,2,3,5,6,7,8,9,10,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59]" />
                      </vs-select>
                    </div>
                    <div class="vx-col md:w-1/5">
                      <vs-select label="Jours" autocomplete multiple class="selectExample w-full" v-model.lazy="days">
                        <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item,index) in Jours" />
                      </vs-select>
                    </div>
                  </div>
                </vx-card>
              </div>
            </div>
          </tab-content>
          <tab-content title="Aperçu">
            <div class="vx-row">
              <div class="vx-col md:w-1/3">

              </div>
              <div class="vx-col md:w-1/3">
                <div class="analyze-preview-container">
                  <div class="phone-container sms ios wl ">
                    <div class="phone-content">
                      <p class="sender fs-exclude">{{from}}</p>
                      <p class="msg-details">Message texte<br>ven. 4 févr. 2022 23:30</p>
                      <div class="msg-container fs-exclude">
                        <span dir="auto" class="msg-content">
                          {{text}}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="vx-col md:w-1/3">
                <vx-card :noShadow="true" :cardBorder="true" title="Résumé">
                  <div class="vx-row">

                    <div class="vx-col md:w-1/2 mt-4">
                      <span class="font-bold">
                        Destinataires
                      </span> <br>
                      <span class="font-bold">
                        {{destinations.length}}
                      </span>
                    </div>
                    <div class="vx-col md:w-1/2 mt-4">
                      <span class="font-bold">
                        Messages
                      </span> <br>
                      <span class="font-bold">
                        {{Math.ceil((text.length/160))}}
                      </span>
                    </div>
                    <div class="vx-col md:w-1/2 mt-4">
                      <span class="font-bold">
                        Message le plus court:
                      </span>
                    </div>
                    <div class="vx-col md:w-1/2 mt-4">
                      <span class="font-medium">
                        {{messageCourt}} caractères
                      </span>
                    </div>
                    <div class="vx-col md:w-1/2 mt-4">
                      <span class="font-bold">
                        Message le plus long:
                      </span>
                    </div>
                    <div class="vx-col md:w-1/2 mt-4">
                      <span class="font-medium">
                        {{Math.max(...messageLong)}} caractères
                      </span>
                    </div>
                    <vs-divider></vs-divider>
                    <div class="vx-col w-full mt-4">
                      <h5>Options avancées</h5>
                    </div>
                    <div class="vx-col md:w-1/2 mt-4">
                      <span class="font-bold">
                        Période de validité:
                      </span>
                    </div>
                    <div class="vx-col md:w-1/2 mt-4">
                      <span class="font-medium">
                        48 heures
                      </span>
                    </div>
                    <div class="vx-col md:w-1/2 mt-4">
                      <span class="font-bold">
                        Vitesse de livraison:
                      </span>
                    </div>
                    <div class="vx-col md:w-1/2 mt-4">
                      <span class="font-medium">
                        Illimité
                      </span>
                    </div>
                    <vs-divider></vs-divider>
                    <div class="vx-col w-full mt-4">
                      <h5>Options de planification</h5>
                    </div>
                    <div class="vx-col md:w-1/2 mt-4">
                      <span class="font-bold">
                        Fuseau horaire:
                      </span>
                    </div>
                    <div class="vx-col md:w-1/2 mt-4">
                      <span class="font-medium">
                        {{fuseauHoraire}}
                      </span>
                    </div>
                    <div class="vx-col md:w-1/2 mt-4">
                      <span class="font-bold">
                        Créneau horaire de l'envoi:
                      </span>
                    </div>
                    <div class="vx-col md:w-1/2 mt-4">
                      <span class="font-medium">
                        <template v-if="days.length === 7">
                          Quotidien {{hour}}H{{minute}}mn - {{toHour}}H{{toMinute}}mn
                        </template>
                        <template v-else>
                          <span v-for="(jour, index) in days" :key="index">
                            <template v-if="jour === '0'">
                              Lundi,
                            </template>
                            <template v-if="jour === '1'">
                              Mardi,
                            </template>
                            <template v-if="jour === '2'">
                              Mercredi,
                            </template>
                            <template v-if="jour === '3'">
                              Jeudi,
                            </template>
                            <template v-if="jour === '4'">
                              Vendredi,
                            </template>
                            <template v-if="jour === '5'">
                              Samedi
                            </template>
                          </span> <br> {{hour}}H{{minute}}mn - {{toHour}}H{{toMinute}}mn
                        </template>
                      </span>
                    </div>
                  </div>
                </vx-card>
                <div class="vx-row mt-4">
                  <div class="vx-col">
                    <vs-button color="primary" class="w-full" @click.stop="messageTestPopup=true">ENVOYER UN TEST</vs-button>
                  </div>
                </div>
              </div>
            </div>
          </tab-content>

        </form-wizard>
      </vx-card>
    </div>
  </div>
</template>
<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import ImportExcel from '@/components/excel/ImportExcel.vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
export default {
  components: {
    FormWizard,
    TabContent,
    flatPickr,
    ImportExcel,
    VuePerfectScrollbar
  },
  data () {
    return {
      tableData: [],
      header: [],
      sheetName: '',

      isSidebarActiveLocal: false,
      idOfficine: null,
      activeUserInfos: null,

      IdUser: null,
      titreCampagne: '',
      from: '',
      text: 'Bonjour les amis message de test Fovea ERP',
      languageCode: { text: 'Default', value: 'Default' },
      intermediateReport: true,
      notifyUrl: null,
      notifyContentType: 'application/json',
      callbackData: null,
      validityPeriod: null,
      sendAt: null,
      hourAt: null,
      minuteAt: null,
      deliveryTime: null,
      fuseauHoraire: '',
      hour: null,
      minute: null,
      toHour: null,
      toMinute: null,
      contactClientsOfficines: [],
      destinations: [],
      days: [],
      Jours: [
        { text: 'Lundi', value: 1 },
        { text: 'Mardi', value: 2 },
        { text: 'Mercredi', value: 3 },
        { text: 'Jeudi', value: 4 },
        { text: 'Vendredi', value: 5 },
        { text: 'Samedi', value: 6 },
        { text: 'Dimanche', value: 0 }
      ],
      options2: [
        { text: 'Default', value: 'Default' },
        { text: 'Baltic', value: 'Baltic' },
        { text: 'Central European', value: 'Central European' },
        { text: 'Cyrillic', value: 'Cyrillic' },
        { text: 'Greek', value: 'Greek' },
        { text: 'Portuguese', value: 'Portuguese' },
        { text: 'Serbian Cyrillic', value: 'Serbian Cyrillic' },
        { text: 'Spanish', value: 'Spanish' },
        { text: 'Turkish', value: 'Turkish' },
        { text: 'Unicode', value: 'Unicode' },
        { text: 'Turkish NLI', value: 'Turkish NLI' },
        { text: 'Portuguese NLI', value: 'Portuguese NLI' }

      ],
      options3: [
        { text: 'Cote d\'Ivoire / Yamoussoukro (GMT)', value: 'Cote d\'Ivoire / Yamoussoukro (GMT)'},
        { text: 'United Arab Emirates / Abu Dhabi (GST)', value: 'United Arab Emirates / Abu Dhabi (GST)' },
        { text: 'Ghana / Accra (GMT)', value: 'Ghana / Accra (GMT)' },
        { text: 'Australia / Adelaide (ACST/ACDT)', value: 'Australia / Adelaide (ACST/ACDT)' },
        { text: 'Ethiopia / Addis Abeba (EAT)', value: 'Ethiopia / Addis Abeba (EAT)' },
        { text: 'Qatar / Doha (AST)', value: 'Qatar / Doha (AST)' },
        { text: 'Jordan / Amman (EET/EEST)', value: 'Jordan / Amman (EET/EEST)' },
        { text: 'United States / Adak (HST/HDT)', value: 'United States / Adak (HST/HDT)' },
        { text: 'Algeria / Algies (CET)', value: 'Algeria / Algies (CET)' },
        { text: 'Bahrain / Manama (AST)', value: 'Bahrain / Manama (AST)' },
        { text: 'Netherlands / Amsterdam (CET/CEST)', value: 'Netherlands / Amsterdam (CET/CEST)' },
        { text: 'Russia / Anadyr (ANAT)', value: 'Russia / Anadyr (ANAT)' },
        { text: 'United States / Anchorage (AKST/AKDT)', value: 'United States / Anchorage (AKST/AKDT)' }

      ],
      configdateTimePicker: {
        enableTime: true,
        enableSeconds: true,
        noCalendar: true
      },
      messageTestPopup: false,
      singleDestination: '',
      oldMessageLength: 0,
      currentMessageLength: 0,
      courtMessage: '',
      longMessage: '',
      restMessage: '',
      messageLengthArray: []
    }
  },
  computed: {
    officines () {
      return this.$store.state.officine.officines
    },
    repertoires () {
      return this.$store.state.sms_marketing.repertoires
    },
    messageCourt () {
      const quotient = Math.ceil((this.text.length / 160))
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.courtMessage = this.text
      for (let index = 1; index <= quotient; index++) {
        if (this.courtMessage.length > 160) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.courtMessage = this.courtMessage.substring(160, this.courtMessage.length)

        } else {
          return this.courtMessage.length
        }

      }

    },
    messageLong () {
      const quotient = Math.ceil((this.text.length / 160))
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.longMessage = this.text
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.restMessage = this.text
      for (let index = 0; index < quotient; index++) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        if (index === 0) this.messageLengthArray = []
        console.log('index', index)
        if (this.restMessage.length > 160) {
          console.log(this.restMessage.length)
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.longMessage = this.restMessage.substring(0, 160)
          console.log('new', this.longMessage.length)
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.messageLengthArray.push(this.longMessage.length)
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.restMessage = this.restMessage.substring(160, this.restMessage.length)
          console.log(this.restMessage.length)
        } else {
          return this.messageLengthArray
        }

      }
    },
    scrollbarTag () { return this.$store.getters.scrollbarTag }
  },
  methods: {
    loadDataInTable ({ results, header, meta }) {
      this.header = header
      this.tableData = results
      this.sheetName = meta.sheetName
    },
    countMessage () {
      let messageLength = 160
      if (messageLength > 0) {
        messageLength = messageLength - this.messageCourt ? this.messageCourt : 160
        return  messageLength
      } else {
        messageLength = 160
      }

    },
    createDiffusion () {
      const payload = {
        IdOfficine: this.idOfficine,
        IdUser: this.IdUser,
        titreCampagne: this.titreCampagne,
        from: this.from,
        destinations: this.destinations,
        text: this.text,
        flash: this.flash,
        transliteration: this.transliteration,
        languageCode: this.languageCode,
        intermediateReport: this.intermediateReport,
        notifyUrl: this.notifyUrl,
        notifyContentType: this.notifyContentType,
        callbackData: this.callbackData,
        validityPeriod: this.validityPeriod,
        sendAt: this.sendAt,
        hourAt: this.hourAt,
        minuteAt: this.minuteAt,
        hour: this.hour,
        minute: this.minute,
        toHour: this.toHour,
        toMinute: this.toMinute,
        days: this.days,
        fuseauHoraire: this.fuseauHoraire
      }

      this.$vs.loading({
        type:'sound'
      })

      this.$store.dispatch('sms_marketing/createDiffusion', payload)
        .then(() => {
          this.$vs.loading.close()
          this.$vs.notify({
            title: 'success',
            text: 'success',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'primary'
          })
          this.$router.push('/apps/sms-marketing/campagne-sms').catch(() => {})
        })
        .catch((err) => {
          console.log(err)
          this.$vs.notify({
            title: 'error',
            text: `${err.message}`,
            iconPack: 'feather',
            icon: 'icon-error',
            color: 'danger'
          })
          this.$vs.loading.close()
        })
    },
    SendTestSmsMessage () {
      const payload = {
        senderName: this.from,
        senderAdress: '2250777219794',
        address: this.singleDestination,
        message: this.text
      }

      this.$vs.loading({
        type:'sound'
      })

      this.$store.dispatch('sms_marketing/SendTestSmsMessage', payload)
        .then(() => {
          this.$vs.loading.close()
        })
        .catch((err) => {
          console.log(err)
          this.$vs.notify({
            title: 'error',
            text: `${err.message}`,
            iconPack: 'feather',
            icon: 'icon-error',
            color: 'danger'
          })
          this.$vs.loading.close()
        })
    },
    ImportContact () {
      /* this.$vs.loading({
        type:'sound'
      }) */
      this.tableData.forEach(item => {
        this.destinations.push({to: item.Contacts, nom: item.Nom, prenoms: item.Prenoms, messageId: '', statusMessage: '', statusCode: null})
      })
      this.isSidebarActiveLocal = false
      /* this.$vs.loading.close() */
    },
    addDestination () {
      this.destinations = []
      this.contactClientsOfficines.forEach(items => {
        items.forEach(item => {
          this.destinations.push({to: item.Contacts, nom: item.Nom, prenoms: item.Prenoms, messageId: '', statusMessage: '', statusCode: null})
        })
      })
    },
    activeUserInfo () {
      this.activeUserInfos = JSON.parse(localStorage.getItem('userInfo'))
      this.idOfficine = this.activeUserInfos.IdOfficine
      this.IdUser = this.activeUserInfos._id


      this.getRepertoiresByOfficine(JSON.parse(localStorage.getItem('userInfo')).IdOfficine)
    },
    getRepertoiresByOfficine (id) {
      this.$vs.loading({
        type:'sound'
      })
      this.$store.dispatch('sms_marketing/getRepertoiresByOfficine', id)
        .then(() => {
          this.$vs.loading.close()
        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close()
          this.$vs.notify({
            title: 'error',
            text: `${err.message}`,
            iconPack: 'feather',
            icon: 'icon-error',
            color: 'danger'
          })
        })
    },
    getOfficines () {
      this.$store.dispatch('officine/getOfficines')
        .then(() => {

        })
        .catch((err) => {
          console.log(err)
          this.$vs.notify({
            title: 'error',
            text: `${err}`,
            iconPack: 'feather',
            icon: 'icon-error',
            color: 'danger'
          })
        })
    }
  },
  created () {
    this.activeUserInfo()
    if (!(this.$store.state.officine.officines.length > 0)) this.getOfficines()
  }
}
</script>
<style lang="scss" scoped>
  .analyze-preview-container {
    width: 100%;
    text-align: center;
  }
  .analyze-preview-container .phone-container.sms.wl {
    background-image: url(https://d2ygi9hnn4h2ig.cloudfront.net/broadcast-sms/1.7.41/images/phone-sms-no-logo.png);
  }
  .analyze-preview-container .phone-container.sms {

    background-size: 100%;
    background-position: 50%;
    width: 322px;
    height: 634px;
    top: 120px;
   /*  left: calc(50% - 211px); */
    transform: translateX(-50%);
    margin-bottom: 120px;
    background-image: url(https://d2ygi9hnn4h2ig.cloudfront.net/broadcast-sms/1.7.41/images/phone-sms.png);
}

.analyze-preview-container .phone-content {
    position: absolute;
    top: 81px;
    left: 24px;
    width: 273px;
    height: 476px;
    overflow: hidden;
}

.analyze-preview-container .phone-container.sms .sender {
    margin-top: 50px;
}

.analyze-preview-container .phone-container.sms .msg-details {
    font-size: smaller;
}

.analyze-preview-container .phone-container.sms .msg-container {
    height: 318px;
    overflow: hidden;
    overflow-y: auto;
    overflow-wrap: break-word;
    word-break: break-word;
}

.analyze-preview-container .phone-container.sms .msg-content {
    background-color: #e6e6eb;
    text-align: left;
    padding: 5px 10px;
    margin: 5px 10px;
    border-radius: 20px;
    float: left;
    white-space: pre-wrap;
}

.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 600px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
    // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
    height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);

    &:not(.ps) {
      overflow-y: auto;
    }
}

</style>
